.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-div {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
